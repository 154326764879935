<p>
    <a role="button" (click)="return('customerQuotes')"><< Return to customer quotes</a>
</p>

<app-customer-header [customerId]="customerId" *ngIf="customerId > 0"></app-customer-header>

<app-spinner-with-overlay [showSpinner]="loading">
    <div *ngIf="!!quote" class="row">
        <div class="col-lg-7">
            <div class="card">
                <div class="card-body">
					<div class="row">
						<div class="col-6">
							<h4><strong>Order Summary</strong></h4>
						</div>
						<div class="col-6 text-end">
							<button type="button" class="btn btn-success btn-lg px-5" (click)="sendQuoteToCustomer()">
									Email Customer
							</button>
						</div>
					</div>

                    <ng-container *ngIf="quote.quoteData!.length > 1">
                        <p>{{ quote.quoteData!.length }} items in order</p>
                    </ng-container>
                    <ng-container *ngIf="quote.quoteData!.length === 1">
                        <p>{{ quote.quoteData!.length }} item in order</p>
                    </ng-container>
                    <div *ngIf="!!quoteLineItems" class="row">
                        <div class="col">
                            <div *ngFor="let item of quoteLineItems.categories![0].lineItems" class="card mt-2">
                                <div class="card-header" role="tab">
                                    <div class="hstack">
                                        <strong>{{ item.name }}</strong>
                                        <div class="ms-auto">
                                            <b>{{ item.amount | currency:'USD' }}</b>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <ul *ngFor="let desc of item.description">
                                        <li>{{ desc }}</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="card mt-2">
                                <div class="card-body">
                                    <div class="mt-3 d-flex justify-content-between">
                                        <p>Subtotal</p>
                                        <p>{{ quote.subtotal | currency:'USD' }}</p>
                                    </div>
									<ng-container *ngIf="quote.bundles && quote.bundles.length > 0">
										<div class="mt-3 d-flex justify-content-between">
											<p>Bundle Discount</p>
											<p>({{ getBundleDiscount() | currency:'USD' }})</p>
										</div>
									</ng-container>
                                    <ng-container *ngIf="quote.couponDiscounts !== 0">
                                        <div class="mt-3 d-flex justify-content-between">
                                            <p>
                                                Discount <span *ngIf="!!quote.couponCode">({{ quote.couponCode }})</span>
                                            </p>
                                            <p>({{ quote.couponDiscounts | currency:'USD' }})</p>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="quote.ventureOneDiscount !== 0">
                                        <div class="mt-3 d-flex justify-content-between">
                                            <p>
                                                Venture One Discount
                                            </p>
                                            <p>({{ quote.ventureOneDiscount | currency:'USD' }})</p>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="getSalesTax() > 0">
                                        <div class="mt-3 d-flex justify-content-between">
                                            <p>
                                                Sales Tax <br />
                                            </p>
                                            <p>{{ getSalesTax() | currency:'USD' }}</p>
                                        </div>
										<ul *ngIf="getGST() > 0 || getHST() > 0 || getPST() >0">
											<li class="mt-1 px-5 d-flex justify-content-between">
												<p>GST</p>
												<p>{{getGST() | currency:'USD'}}</p>
											</li>
											<li class="mt-1 px-5 d-flex justify-content-between">
												<p>HST</p>
												<p>{{getHST() | currency:'USD'}}</p>
											</li>
											<li class="mt-1 px-5 d-flex justify-content-between">
												<p *ngIf="billingAddress?.state === 'QC'">QST</p>
												<p *ngIf="billingAddress?.state !== 'QC'">PST</p>
												<p>{{getPST() | currency:'USD'}}</p>
											</li>
										</ul>
                                    </ng-container>
                                </div>
                                <div class="card-footer">
                                    <div class="hstack">
                                        <div>Order Total</div>
                                        <div class="ms-auto">
                                            <b>{{ quote.total | currency:'USD' }}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-5">
            <div class="card">
                <div class="card-body">
                    <h4 *ngIf="quote.total > 0"><strong>Payment</strong></h4>
					<app-alert-box  *ngIf="createOrderErrorMessage !== ''" alertMode="danger">
						{{createOrderErrorMessage}}
					</app-alert-box>
                    <div class="row mt-3">
                        <div class="col">
                            <div *ngIf="totalBalance > 0 && quote.total > 0" class="card mt-2">
                                <div class="card-header" role="tab" id="purchaseOrderHeading">
                                    <strong>Use Customer Balance</strong>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-check">
                                                <input class="form-check-input"
                                                    [(ngModel)]="useBalance" type="checkbox"
													(ngModelChange)="updateForms()"
                                                    id="useBalance" />
                                                <label for="useBalance" class="form-check-label">
                                                    Use Customer Balance ({{totalBalance | currency}}) for payment
                                                </label>
                                            </div>
                                        </div>
									</div>
                                </div>
                            </div>
                            <div class="card mt-2" *ngIf="quote.total > 0">
                                <div class="card-header" role="tab" id="creditCardHeading">
                                    <strong>Credit Card</strong>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-check">
                                                <input class="form-check-input"
													[disabled]="useBalance && totalBalance >= this.quote.total"
                                                    [checked]="creditCardPaymentType === 'payInFull'" type="radio"
                                                    id="payInFullInput" (click)='selectPaymentPlan("payInFull")' />
                                                <label for="payInFullInput" class="form-check-label">
                                                    Pay in full using CC
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-6" *ngIf="!disablePaymentPlan">
                                            <div class="form-check">
                                                <input [checked]="creditCardPaymentType === 'paymentPlan'"
                                                    class="form-check-input" type="radio" id="paymentPlanInput"
													[disabled]="useBalance && totalBalance >= this.quote.total"
                                                    (click)='selectPaymentPlan("paymentPlan")' />
                                                <label for="paymentPlanInput" class="form-check-label">
                                                    Payment plan
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="creditCardPaymentType === 'paymentPlan'">
                                        <div class="row mt-2 paymentPlanInfo">
                                            <div class="col-12">
                                                <small>50% deposit due with order. $25.00 service fee for each
                                                    subsequent
                                                    payment
                                                    applies. Subsequent payments
                                                    will be automatically charged to your credit card on file on
                                                    the
                                                    dates
                                                    indicated
                                                    below:</small>
                                            </div>
                                        </div>
                                        <div *ngFor="let financePayment of quote.paymentSchedule"
                                            class="row mt-2 paymentPlanInfo">
                                            <div class="col-6">
                                                <small>{{financePayment.description}}</small>
                                            </div>
                                            <div class="col">
                                                <small>{{financePayment.dueOn}}</small>
                                            </div>
                                            <div class="col-3 text-end">
                                                <small>{{financePayment.amount.toLocaleString('en-US', { style:
                                                    'currency', currency: 'USD' })}}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="creditCardPaymentType !== ''" class="row mt-2">
                                        <form [formGroup]="creditCardForm" id="creditCardForm">
                                            <div class="form-group">
                                                <div class="col-12 mb-2">
                                                    <div class="row">
                                                        <div class="col-md-9">
															<div class="input-group">
																<input [type]="showCCNum ? 'text' : 'password'" id="creditCardNumber" formControlName='creditCardNumber'
																	id="creditCardNumber" class="form-control mt-2" minlength="13"
																	maxlength="16" placeholder="Credit Card Number"
																	[ngClass]="{ 'is-invalid':  paymentType === 'Credit Card' && submitted && creditCardControls.creditCardNumber.errors}"
																	data-validate="required" />
																<span class="input-group-text mt-2" (click)="toggleShowCCNum()">
																	<i [ngClass]="showCCNum ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
																</span>
															</div>
															<div *ngIf="creditCardControls.creditCardNumber.errors"
																class="input-error">
																<div
																	*ngIf="creditCardControls.creditCardNumber.errors.required && creditCardControls.creditCardNumber.touched">
																	Credit Card is required</div>
																<div
																	*ngIf="creditCardControls.creditCardNumber.errors.minlength">
																	Minimum Credit Card length is 13</div>
															</div>

                                                        </div>
                                                        <div class="col-md-3">
															<div class="input-group">
																<input [type]="showCVV ? 'text' : 'password'"  formControlName='cvv' id="cvv"
																	class="form-control mt-2" placeholder="CVV" minlength="3"
																	maxlength="4" data-validate="required"
																	[ngClass]="{ 'is-invalid':  paymentType === 'Credit Card' && submitted && creditCardControls.cvv.errors}"/>
																	<span class="input-group-text mt-2" (click)="toggleShowCVV()">
																		<i [ngClass]="showCVV ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
																	</span>
															</div>
                                                            <div *ngIf="creditCardControls.cvv.errors && creditCardControls.cvv.touched"
                                                                class="input-error">
                                                                <div *ngIf="creditCardControls.cvv.errors.required">
                                                                    CVV is required</div>
                                                                <div *ngIf="creditCardControls.cvv.errors.minlength">
                                                                    Minimum length is 3</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mt-2">
                                                        <div class="col-6">
                                                            <select formControlName='expirationMonth' id="expirationMonth"
                                                                class="form-select" [ngClass]="{ 'is-invalid': paymentType === 'Credit Card' && submitted && creditCardControls.expirationMonth.errors}"
                                                                (change)="adjustYear($any($event.target).value)">
                                                                <option value="" selected disabled>Expiration
                                                                    Month
                                                                    <span class="caret"></span>
                                                                </option>
                                                                <option value="01">January</option>
                                                                <option value="02">February</option>
                                                                <option value="03">March</option>
                                                                <option value="04">April</option>
                                                                <option value="05">May</option>
                                                                <option value="06">June</option>
                                                                <option value="07">July</option>
                                                                <option value="08">August</option>
                                                                <option value="09">September</option>
                                                                <option value="10">October</option>
                                                                <option value="11">November</option>
                                                                <option value="12">December</option>
                                                            </select>
                                                            <div *ngIf="creditCardControls.expirationMonth.errors && creditCardControls.expirationMonth.touched"
                                                                class="input-error">
                                                                <div *ngIf="creditCardControls.expirationMonth.errors.required">
                                                                    Expiration Month is required</div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <select formControlName='expirationYear' id="expirationYear"
                                                                class="form-select" [ngClass]="{ 'is-invalid': paymentType === 'Credit Card' && submitted && creditCardControls.expirationYear.errors}">
                                                                <option value="" selected disabled>Expiration
                                                                    Year <span class="caret"></span></option>
                                                                <option *ngFor="let year of creditCardYear" value="{{year}}">
                                                                    {{year}}</option>
                                                            </select>
                                                            <div *ngIf="creditCardControls.expirationYear.errors && creditCardControls.expirationYear.touched"
                                                                class="input-error">
                                                                <div *ngIf="creditCardControls.expirationYear.errors.required">
                                                                    Expiration Year is required</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="card mt-2" *ngIf="quote.total > 0">
                                <div class="card-header" role="tab" id="achHeading">
                                    <strong>ACH</strong>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-check">
                                                <input class="form-check-input"
													[disabled]="useBalance && totalBalance >= this.quote.total"
                                                    [checked]="achPaymentType === 'achPayInFull'" type="radio"
                                                    id="achPayInFullInput" (click)='selectACHPaymentPlan("achPayInFull")' />
                                                <label for="achPayInFullInput" class="form-check-label">
                                                    Pay in full using ACH
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-6" *ngIf="!disablePaymentPlan">
                                            <div class="form-check">
                                                <input [checked]="achPaymentType === 'achPaymentPlan'"
                                                    class="form-check-input" type="radio" id="achPaymentPlanInput"
													[disabled]="useBalance && totalBalance >= this.quote.total"
                                                    (click)='selectACHPaymentPlan("achPaymentPlan")' />
                                                <label for="achPaymentPlanInput" class="form-check-label">
                                                    Payment plan
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="achPaymentType === 'achPaymentPlan'">
                                        <div class="row mt-2 achPaymentPlanInfo">
                                            <div class="col-12">
                                                <small>50% deposit due with order. $25.00 service fee for each
                                                    subsequent
                                                    payment
                                                    applies. Subsequent payments
                                                    will be automatically charged to your credit card on file on
                                                    the
                                                    dates
                                                    indicated
                                                    below:</small>
                                            </div>
                                        </div>
                                        <div *ngFor="let financePayment of quote.paymentSchedule"
                                            class="row mt-2 paymentPlanInfo">
                                            <div class="col-6">
                                                <small>{{financePayment.description}}</small>
                                            </div>
                                            <div class="col">
                                                <small>{{financePayment.dueOn}}</small>
                                            </div>
                                            <div class="col-3 text-end">
                                                <small>{{financePayment.amount.toLocaleString('en-US', { style:
                                                    'currency', currency: 'USD' })}}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="achPaymentType !== ''" class="row mt-2">
                                        <form [formGroup]="achForm" id="achForm">
                                            <div class="form-group">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-md-9">
															<div class="input-group">
																<input type="text" id="routingNumber" formControlName='routingNumber'
																	id="routingNumber" class="form-control mt-2" minlength="9"
																	maxlength="9" placeholder="Routing Number"
																	[ngClass]="{ 'is-invalid':  paymentType === 'ACH' && submitted && achControls.routingNumber.errors}"
																	data-validate="required" />
															</div>
															<div *ngIf="achControls.routingNumber.errors"
																class="input-error">
																<div
																	*ngIf="achControls.routingNumber.errors.required && achControls.routingNumber.touched">
																	Routing Number is required</div>
																<div
																	*ngIf="achControls.routingNumber.errors.minlength || achControls.routingNumber.errors.maxlength">
																	Routing Number is 9 digits</div>
															</div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
														<div class="col-md-9">
															<div class="input-group">
																<input type="text" id="accountNumber" formControlName='accountNumber'
																	id="accountNumber" class="form-control mt-2" minlength="8"
																	maxlength="12" placeholder="Account Number"
																	[ngClass]="{ 'is-invalid':  paymentType === 'ACH' && submitted && achControls.accountNumber.errors}"
																	data-validate="required" />
															</div>
															<div *ngIf="achControls.accountNumber.errors"
																class="input-error">
																<div
																	*ngIf="achControls.accountNumber.errors.required && achControls.accountNumber.touched">
																	Account Number is required</div>
																<div
																	*ngIf="achControls.accountNumber.errors.minlength || achControls.accountNumber.errors.maxlength">
																	Account Number is 8-12 digits</div>
															</div>
														</div>
                                                    </div>
                                                    <div class="row">
														<div class="col-md-9">
															<div class="input-group">
																<input type="text" id="nameOnAccount" formControlName='nameOnAccount'
																	id="nameOnAccount" class="form-control mt-2" placeholder="Enter Name on Account"
																	[ngClass]="{ 'is-invalid':  paymentType === 'ACH' && submitted && achControls.nameOnAccount.errors}"
																	data-validate="required" />
															</div>
															<div *ngIf="achControls.nameOnAccount.errors"
																class="input-error">
																<div
																	*ngIf="achControls.nameOnAccount.errors.required && achControls.nameOnAccount.touched">
																	Name is required</div>
															</div>
														</div>
                                                    </div>
                                                    <div class="row">
														<div class="col-md-9">
															<div class="input-group">
																<input type="text" id="bankName" formControlName='bankName'
																	id="bankName" class="form-control mt-2" placeholder="Bank Name"
																	[ngClass]="{ 'is-invalid':  paymentType === 'ACH' && submitted && achControls.bankName.errors}"
																	data-validate="required" />
															</div>
															<div *ngIf="achControls.bankName.errors"
																class="input-error">
																<div
																	*ngIf="achControls.bankName.errors.required && achControls.bankName.touched">
																	Bank Name is required</div>
															</div>
														</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="card mt-2" *ngIf="customerPaymentProfiles.length > 0 && quote.total > 0">
                                <div class="card-header" role="tab" id="achHeading">
                                    <strong>Payment Profiles</strong>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-6">
											<div class="form-check">
                                                <input [checked]="profilePaymentType === 'payInFullUsingCustomerProfile'"
                                                    class="form-check-input" type="radio" id="payInFullUsingCustomerProfile"
													[disabled]="useBalance && totalBalance >= this.quote.total"
                                                    (click)='selectProfilePaymentPlan("payInFullUsingCustomerProfile")' />
                                                <label for="payInFullUsingCustomerProfile" class="form-check-label">
                                                    Pay in full using Customer Profile
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-6" *ngIf="!disablePaymentPlan">
											<div class="form-check">
                                                <input [checked]="profilePaymentType === 'paymentPlanUsingCustomerProfile'"
                                                    class="form-check-input" type="radio" id="paymentPlanUsingCustomerProfileInput"
													[disabled]="useBalance && totalBalance >= this.quote.total"
                                                    (click)='selectProfilePaymentPlan("paymentPlanUsingCustomerProfile")' />
                                                <label for="paymentPlanUsingCustomerProfileInput" class="form-check-label">
                                                    Payment Plan using Customer Profile
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="profilePaymentType === 'paymentPlanUsingCustomerProfile'">
                                        <div class="row mt-2 paymentPlanInfo">
                                            <div class="col-12">
                                                <small>50% deposit due with order. $25.00 service fee for each
                                                    subsequent
                                                    payment
                                                    applies. Subsequent payments
                                                    will be automatically charged to your credit card on file on
                                                    the
                                                    dates
                                                    indicated
                                                    below:</small>
                                            </div>
                                        </div>
                                        <div *ngFor="let financePayment of quote.paymentSchedule"
                                            class="row mt-2 paymentPlanInfo">
                                            <div class="col-6">
                                                <small>{{financePayment.description}}</small>
                                            </div>
                                            <div class="col">
                                                <small>{{financePayment.dueOn}}</small>
                                            </div>
                                            <div class="col-3 text-end">
                                                <small>{{financePayment.amount.toLocaleString('en-US', { style:
                                                    'currency', currency: 'USD' })}}</small>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="profilePaymentType === 'payInFullUsingCustomerProfile' || profilePaymentType === 'paymentPlanUsingCustomerProfile'">
                                        <form [formGroup]="customerProfileForm">
                                            <div class="col-12" style="padding: 20px 30px 20px 30px;">
                                                <select type="select" formControlName="customerPaymentProfileId" class="form-select"
												[ngClass]="{ 'is-invalid': paymentType === 'ACH' && submitted && customerProfileControls.customerPaymentProfileId.errors}">
                                                    <option *ngFor="let x of customerPaymentProfiles" value="{{x.customerPaymentProfileId}}">
                                                        {{(x.creditCardMaskedData?.cardType ?? 'Checking')}} - {{(x.creditCardMaskedData?.maskedCard ?? x.achMaskedData?.accountNumber) }} - {{x.fullname}}
                                                    </option>
                                                </select>
                                                <div *ngIf="customerProfileControls.customerPaymentProfileId.errors && customerProfileControls.customerPaymentProfileId.touched"
                                                    class="input-error">
                                                    <div *ngIf="customerProfileControls.customerPaymentProfileId.errors.required">
                                                        Customer Payment Profile Id is required</div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="creditKeyActive && quote.total > 0" class="card mt-2">
                                <div class="card-header" role="tab" id="creditKeyHeading">
                                    <strong>Credit Key</strong>
                                </div>
                                <div class="row">
                                    <form [formGroup]="creditKeyPostAuthForm">
                                        <div class="col-12" style="padding: 20px 30px 20px 30px;">
                                            <img class="mw-100 mh-100 mb-3" src="assets/creditKeyLogo.png" />
                                            <input type="text" formControlName='creditKeyPostAuthRefNumber'
                                                id="creditKeyPostAuthRefNumber" class="form-control"
												[ngClass]="{ 'is-invalid':  paymentType === 'CreditKeyPostAuth' && submitted && ckControls.creditKeyPostAuthRefNumber.errors}"
                                                (focus)="creditKeyPostAuthSelected()" placeholder="Credit Key Reference Number">
                                            <div *ngIf="ckControls.creditKeyPostAuthRefNumber.errors && ckControls.creditKeyPostAuthRefNumber.touched"
                                                class="input-error">
                                                <div *ngIf="ckControls.creditKeyPostAuthRefNumber.errors.required">
                                                    Credit Key Reference Number is required</div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="card mt-2" *ngIf="quote.total > 0">
                                <div class="card-header" role="tab" id="purchaseOrderHeading">
                                    <strong>Purchase Order</strong>
                                </div>
                                <div class="row">
                                    <form [formGroup]="purchaseOrderForm">
                                        <div class="col-12" style="padding: 20px 30px 20px 30px;">
                                            <input type="text" formControlName='purchaseOrderNumber'
                                                id="purchaseOrderNumber" class="form-control"
												[ngClass]="{ 'is-invalid': paymentType === 'Purchase Order' && submitted && purchaseOrderControls.purchaseOrderNumber.errors}"
                                                (focus)="purchaseOrderSelected()" placeholder="Purchase Order Number">
                                            <div *ngIf="purchaseOrderControls.purchaseOrderNumber.errors && purchaseOrderControls.purchaseOrderNumber.touched"
                                                class="input-error">
                                                <div *ngIf="purchaseOrderControls.purchaseOrderNumber.errors.required">
                                                    PO Number is required</div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-auto m-auto">
                                    <button type="button" class="btn btn-success btn-lg px-5" (click)="placeOrder()">
                                            COMPLETE ORDER
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-spinner-with-overlay>
