import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
	CartProduct, Coupon, Customer,
	DropsKind, PostageType, PriceCalculatorRequest,
	ShoppingCart, Site, USelectDesignType, ValidateCoupon, WLProduct
} from '@taradel/web-api-client';
import { CustomerService } from 'services/customer.service';
import { DistributionsService, USelect } from 'services/distributions.service';
import { ProductCategories, ProductCategoryOptions } from 'services/products.service';
import { SalesApiService, DirectMailProducts } from 'services/sales-api.service';
import { SitesService } from 'services/sites.service';
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ToastService } from 'services/toast.service';
import { sumBy } from 'lodash';
import { ShoppingCartService } from 'services/shopping-cart.service';
import { PrintAndShipService } from 'services/print-and-ship.service';
import { PricingService } from 'services/pricing.service';
import { CouponService } from 'services/coupon.service';
import { SiteConfigService } from 'services/site-config.service';
import { AuthenticationService } from 'services/authentication.service';
import { Bundle, BundleDiscountType, BundleDisplay, CartBundle, Distribution, SiteBundle, USelectMethod } from '@taradel/admin-api-client';
import { BundlesService } from 'services/bundles.service';

export interface AddedProduct {
	product: CartProduct;
	pricing: Pricing;
	discount: number;
}
export interface Pricing {
	total: number;
	shippingPrice?: number;
}

@Component({
	selector: 'app-create-shopping-cart',
	templateUrl: './create-shopping-cart.component.html',
	styleUrls: ['./create-shopping-cart.component.scss']
})
export class CreateShoppingCartComponent implements OnInit {
	showSpinner = true;
	customerId = 0;
	customer!: Customer;
	siteId?: number;
	site!: Site;
	distributionId = 0;
	distribution?: Distribution;
	siteProducts: WLProduct[] = [];
	submitted = false;
	sites: Site[] = [];
	siteCoupons: Coupon[] = [];
	siteBundles: SiteBundle[] = [];
	siteBundleDisplay: BundleDisplay[] = [];
	BundleDiscountType = BundleDiscountType;
	facebookSetupFee = 0;
	displaySetupFee = 0;
	shoppingCart?: ShoppingCart;
	addedProducts: AddedProduct[] = [];
	addedBundleProducts: AddedProduct[] = [];
	@ViewChild('instance', { static: true }) instance: NgbTypeahead | undefined;
	focus$ = new Subject<string>();
	click$ = new Subject<string>();
	addressAdded: boolean = false;
	billingAddressId?: number;
	couponCode = '';
	couponValidationResponse?: Coupon;
	cartProducts: CartProduct[] = [];
	hasPrintAndShipCategories = false;
	showBundles = false;
	dropFeeAmt: number = 0;
	isSalesAdmin = false;
	includeExtraCopiesOption = false;
	isSoloExtraCopiesCart = true;
	siteUselects: USelectMethod[] = [];
	selectedBundleNew: CartBundle[] = [];
	displayBundles: BundleDisplay[] = [];
	bundleProducts: CartProduct[] = [];
	bundleToRemove?: Bundle;
	bundleDiscount = 0;

	constructor(
		private authService: AuthenticationService,
		private route: ActivatedRoute,
		private router: Router,
		private sitesService: SitesService,
		private pricingService: PricingService,
		private customerService: CustomerService,
		private printandShipService: PrintAndShipService,
		private salesApiService: SalesApiService,
		private shoppingCartService: ShoppingCartService,
		private distributionsService: DistributionsService,
		private toastService: ToastService,
		public couponService: CouponService,
		private siteConfigService: SiteConfigService,
		private bundlesService: BundlesService,
		private modalService: NgbModal

	) {
		this.customerId = parseInt(route.snapshot.paramMap.get('customerId') ?? '0', 10);
		this.siteId = parseInt(route.snapshot.paramMap.get('siteId') ?? '0', 10);
		this.distributionId = parseInt(route.snapshot.paramMap.get('distributionId') ?? '0', 10);
	}

	async ngOnInit(): Promise<void> {
		try {
			[this.customer, this.site, this.distribution, this.siteBundles] = await Promise.all([
				this.customerService.getCustomer(this.customerId),
				this.sitesService.getSite(this.siteId!),
				this.distributionId > 0 ? this.distributionsService.getDistribution(this.distributionId) : undefined,
                this.distributionId > 0 ? await this.bundlesService.getDistributionRelatedBundles(this.siteId!, this.distributionId) : []
			]);
			this.siteProducts = await this.sitesService.getSiteProducts(this.siteId!);
			if (this.siteId) {
				this.hasPrintAndShipCategories = (await this.printandShipService.getSiteCategories(this.siteId))?.length > 0;
				await this.loadInitialData();
				this.siteCoupons = await this.couponService.getSiteCoupons(this.siteId);
				this.siteUselects = await this.sitesService.getAllUSelect();
				this.populateBundleDisplay();
			}
			this.isSalesAdmin = await this.authService.hasRole('SalesAdmin');
		}
		catch {
			this.toastService.showError('There was a problem loading the component', 'Load Error');
		}
		finally {
			this.showSpinner = false;
		}
	}

	async loadInitialData() {
		this.addedProducts = [];
		this.addedBundleProducts = [];
		this.dropFeeAmt = await this.siteConfigService.getNumber(this.siteId!, 'Product', 'DropFeeAmt');
		try {
			this.shoppingCart = await this.shoppingCartService.getShoppingCart(this.customerId, this.siteId!);
		}
		catch (error: any) {
			this.shoppingCart = undefined;
			if (error.status !== 404) {
				this.toastService.showError('Unable to get shopping cart', 'Error');
			}
		}

		this.includeExtraCopiesOption = this.shoppingCart === undefined && this.siteProducts.some(sp => sp.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.extraCopies);
		this.isSoloExtraCopiesCart = this.shoppingCart?.cartData?.every(cd => cd.uSelectId === USelect.extraCopies) ?? false;
		if (!!this.shoppingCart && this.distributionId && this.shoppingCart.cartData?.some(cd => cd.distributionId !== this.distributionId && cd.uSelectId !== USelect.printAndShip)) {
			this.proceedToNewCartCreation();
		}
		else if (!!this.shoppingCart && (this.shoppingCart.cartData && this.shoppingCart.cartData?.length > 0)) {
			//We know shoppingCart will be populated at this point
			if (this.shoppingCart.bundles) {
				this.bundleDiscount = this.shoppingCart.bundles.reduce((a, b) => {return a + b.totalDiscount;}, 0) ?? 0;
				this.selectedBundleNew =this.shoppingCart?.bundles!;
				const bundleProducts = this.shoppingCart?.bundles!;
				await this.populateBundleFields(this.shoppingCart?.cartData!);
				const activeBundleIds: number[] = [];

				this.siteBundles.forEach(sc => {
					const foundBundle = bundleProducts.find(p => p.bundleId === sc.bundleId);
					if (foundBundle) {
						activeBundleIds.push(foundBundle.bundleId);
					}
				});
				activeBundleIds.forEach(async ab => {
					const bundleDisplay = await this.bundlesService.getBundle(ab);
					this.displayBundles.push(bundleDisplay);
				});
			}
			const nonBundleCartData = this.shoppingCart.cartData.filter(cd => !this.shoppingCart?.bundles?.some(b => b.cartProductIds?.some(id => id === cd.cartProductId)));
			await this.populateFields(nonBundleCartData);
		}
	}

	selectBillingAddress(value: any) {
		this.billingAddressId = value;
	}

	addressFormatter = (x: { address1: string }) => x.address1;

	addPrintAndShipProduct() {
		this.router.navigate(['/customers', this.customerId, 'shop', this.siteId, this.distributionId, 'add-printandship-product']);
	}
	addWLProduct() {
		this.router.navigate(['/customers', this.customerId, 'shop', this.siteId, this.distributionId, 'add-product']);
	}
	addExtraCopiesProduct() {
		this.router.navigate(['/customers', this.customerId, 'shop', this.siteId, this.distributionId, 'add-extra-copies']);
	}
	addBundle(bundle: BundleDisplay) {
		if ((bundle.bundleItemDisplays?.length ?? 0) > 1) {
			this.router.navigate(['/customers', this.customerId, 'shop', this.siteId, this.distributionId, 'add-bundle', bundle.bundleId]);
		}
		else {
			this.addWLProduct();
		}
	}

	async populateFields(cartProducts: CartProduct[]) {
		cartProducts.map(async x => {
			//if (!this.addedBundleProducts.some(b => b.product.cartProductId === x.cartProductId)) {
				let postageType = this.getPostageValue(x);

				const pricing: Pricing = {
					total: 0,
					shippingPrice: 0
				};

				const wlProduct = this.siteProducts.find(p => p.baseProductId === x.baseProductId);

				if (wlProduct?.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.printAndShip) {
					pricing.total = x.price;
					pricing.shippingPrice = x.shippingPrice;
				}
				else {
					let impressions = 1;
					if (x.uSelectId === USelect.targetedEmail) {
						impressions = x.drops?.orderDrops?.length ?? 1;
					}
					const calculator = await this.pricingService.getPriceCalculator(new PriceCalculatorRequest({
						baseProductId: x.baseProductId,
						customerId: this.customerId,
						budget: x.budget,
						postageType,
						quantity: x.quantity,
						impressions,
						siteId: this.siteId!
					}));
					pricing.total = calculator.total;
				}

				this.addedProducts.push({
					discount: 0,
					pricing,
					product: x,
				});
			//}
		});
		if (this.shoppingCart?.couponCode) {
			await this.applyCoupon(this.shoppingCart.couponCode);
		}
	}

	async populateBundleFields(cartProducts: CartProduct[]) {
		this.selectedBundleNew.forEach(sb => {
			sb.cartProductIds?.forEach(p => {
				const bundleProduct = cartProducts.find(e => e.cartProductId === p)!;
				this.bundleProducts.push(bundleProduct);
			});
		});

		this.bundleProducts.map(async x => {
			let postageType = this.getPostageValue(x);

			const pricing: Pricing = {
				total: 0,
				shippingPrice: 0
			};

			const wlProduct = this.siteProducts.find(p => p.baseProductId === x.baseProductId);

			if (wlProduct?.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.printAndShip) {
				pricing.total = x.price;
				pricing.shippingPrice = x.shippingPrice;
			}
			else {
				let impressions = 1;
				if (x.uSelectId === USelect.targetedEmail) {
					impressions = x.drops?.orderDrops?.length ?? 1;
				}
				const calculator = await this.pricingService.getPriceCalculator(new PriceCalculatorRequest({
					baseProductId: x.baseProductId,
					customerId: this.customerId,
					budget: x.budget,
					postageType,
					quantity: x.quantity,
					impressions,
					siteId: this.siteId!
				}));
				pricing.total = calculator.total;
			}

			this.addedBundleProducts.push({
				discount: 0,
				pricing,
				product: x,
			});
		});
		if (this.shoppingCart?.couponCode) {
			await this.applyCoupon(this.shoppingCart.couponCode);
		}
	}

	getPostageValue(cartProduct: CartProduct): PostageType {
		let postageType = PostageType.StandardMail;
		if (cartProduct.attributes) {
			const postageOption = cartProduct.attributes.find(x => parseInt(x.optCatId!, 10) === ProductCategories.PostageClass);
			if (postageOption && parseInt(postageOption.value!, 10) === ProductCategoryOptions.FirstClass) {
				postageType = PostageType.FirstClass;
			}
			else if (postageOption && parseInt(postageOption.value!, 10) === ProductCategoryOptions.NonProfit) {
				postageType = PostageType.NonProfit;
			}
		}
		return postageType;
	}

	getProductName(product: CartProduct): string {
		return this.siteProducts.find(p => p.baseProductId === product.baseProductId)!.name!;
	}

	getQuantity(product: AddedProduct) {
		const quantity = product.product.quantity.toLocaleString('en-US', { minimumFractionDigits: 0 });
		const wlProduct = this.siteProducts.find(x => x.baseProductId === product.product.baseProductId);
		const isCustomFeeProduct = wlProduct?.baseProduct?.uSelectConfigurations![0].uSelectId === USelect.customFee;
		if (isCustomFeeProduct && product.product.budget) {
			return `$ ${product.product.budget.toFixed(2)}`;
		}
		else if (!isCustomFeeProduct && product.product.budget) {
			return `$ ${product.product.budget.toFixed(2)}/day for ${quantity} days`;
		}
		else {
			return quantity;
		}
	}

	getProductPrice(product: AddedProduct) {
		let total = product.pricing.total;
		const wlProduct = this.siteProducts.find(x => x.baseProductId === product.product.baseProductId)!;
		// design fee
		let designFee = 0;
		if (product.product.design?.uSelectDesign === USelectDesignType.ProfessionalDesign) {
			designFee = wlProduct?.designFee ?? wlProduct?.baseProduct?.designFee!;
		}

		// setup fee
		let setupFee = 0;
		switch (wlProduct.baseProduct?.uSelectConfigurations![0].uSelectId) {
			case USelect.facebookAds:
				setupFee = this.facebookSetupFee;
				break;
			case USelect.displayAds:
				setupFee = this.displaySetupFee;
				break;
		}
		// drop fee
		let dropFee = 0;
		if (product.product.drops !== undefined && product.product.drops.orderDrops?.length! > 0) {
			const dropsKind = product.product.drops.orderDrops![0].multiple ? DropsKind.MultipleImpressions : DropsKind.SplitDrops;
			const numberOfDrops = product.product.drops.orderDrops?.length;
			if (dropsKind === DropsKind.SplitDrops && (!!numberOfDrops && numberOfDrops > 1)) {
				dropFee = this.dropFeeAmt * (numberOfDrops - 1);
			}
		}
		total += designFee + setupFee + dropFee + (product.pricing.shippingPrice ?? 0);
		return total;
	}

	getBundleItemDiscount(product: AddedProduct): number {
		const bundleId = this.shoppingCart?.bundles?.find(b => b.cartProductIds?.some(cp => cp === product.product.cartProductId))?.bundleId ?? 0;
		if (bundleId > 0) {
			const bundle = this.siteBundles.find(sb => sb.bundle?.bundleItems?.some(bi => bi.productConfiguration?.some(pc => pc === product.product.baseProductId)));
			if (!!bundle) {
				const bundleItem = bundle.bundle?.bundleItems?.find(bi => bi.productConfiguration?.some(pc => pc === product.product.baseProductId));
				if (!!bundleItem) {
					const discount = bundleItem.discountAmount;
					const discountType = bundleItem.discountType;
					return discountType === BundleDiscountType.Dollar ? discount : discount / 100 * product.product.price;
				}
				return 0;
			}
			return 0;
		}
		return 0;
	}

	getBundleItemDiscountedPrice(product: AddedProduct): number {
		const discount = this.getBundleItemDiscount(product);
		return product.product.price - discount;
	}

	getTotalDiscount() {
		return sumBy(this.addedProducts.concat(this.addedBundleProducts), product => product.discount);
	}

	getTotalVentureOneDiscount() {
		return sumBy(this.addedProducts.concat(this.addedBundleProducts), product => product.product.ventureOneDiscount);
	}

	getTotalPrice() {
		return sumBy(this.addedProducts.concat(this.addedBundleProducts), product => this.getProductPrice(product));
	}

	async proceedToNewCartCreation() {
		// empty current cart
		this.showSpinner = true;
		await this.shoppingCartService.emptyDirectMailCart(this.siteId!, this.customerId);
		this.addedBundleProducts = [];
		this.selectedBundleNew = [];
		await this.loadInitialData();
		this.showSpinner = false;
	}

	async emailCart() {
		try {
			this.showSpinner = true;
			await this.salesApiService.emailCart(this.site.siteId, this.customer.customerID);
			this.toastService.showSuccess('Cart emailed successfully');
		}
		catch (error: any) {
			this.toastService.showError('There was an error emailing the cart');
		}
		finally {
			this.showSpinner = false;
		}
	}

	async proceedToCheckout() {
		this.submitted = true;
		if (!this.shoppingCart || !this.billingAddressId) {
			return;
		}

		this.showSpinner = true;
		const productDiscounts: { [key: string]: number } = {};
		if (this.couponCode.length === 0) {
			this.addedProducts.forEach(x => {
				if (x.discount !== 0) {
					productDiscounts[x.product.cartProductId!] = x.discount;
				}
			});
			this.addedBundleProducts.forEach(x => {
				if (x.discount !== 0) {
					productDiscounts[x.product.cartProductId!] = x.discount;
				}
			});
		}
		let quoteId = '';
		let quoteCreated = true;
		try {
			quoteId = await this.salesApiService.generateQuote(this.customerId, this.siteId!, this.billingAddressId!, this.couponCode, productDiscounts);
		}
		catch (error: any) {
			quoteCreated = false;
			if (error.status === 400) {
				this.toastService.showError(JSON.parse(error.response));
			}
			if (error.status === 401) {
				this.toastService.showError(JSON.parse(error.response));
			}
		}
		finally {
			this.showSpinner = false;
			if (quoteCreated) {
				await this.router.navigateByUrl(`/customers/${this.customerId}/shop/${quoteId}/checkout`);
			}
		}
	}

	async removeCartProduct(cartProductId: string) {
		try {
			this.showSpinner = true;
			await this.shoppingCartService.removeShoppingCart(this.customerId, this.siteId!, cartProductId);
			const addedProduct = this.addedProducts.find(ap => ap.product.cartProductId === cartProductId);
			if (DirectMailProducts.some(dmp => dmp === addedProduct?.product.uSelectId)) {
				const ecProduct = this.addedProducts.find(ap => ap.product.uSelectId === USelect.extraCopies);
				if (!!ecProduct) {
					await this.shoppingCartService.removeShoppingCart(this.customerId, this.siteId!, ecProduct?.product.cartProductId!);
				}
			}
			await this.loadInitialData();
			if (this.shoppingCart?.couponCode) {
				await this.applyCoupon(this.shoppingCart?.couponCode);
			}
		}
		catch (error: any) {
			if (error.status === 400) {
				this.toastService.showError(error.response);
			}
			else {
				this.toastService.showError('There was an error removing the product from cart');
			}
		}
		finally {
			this.showSpinner = false;
		}
	}

	editCartProduct(addedProduct: AddedProduct) {
		if (addedProduct.product.distributionId > 0) {
			if (addedProduct.product.uSelectId === USelect.extraCopies && this.addedProducts.find(p => DirectMailProducts.some(dmp => dmp === p.product.uSelectId))) {
				const associatedPrintProduct = this.addedProducts.find(p => DirectMailProducts.some(dmp => dmp === p.product.uSelectId));
				this.router.navigate(['/customers', this.customerId, 'shop', this.siteId, this.distributionId, 'edit-product', associatedPrintProduct?.product.cartProductId]);
			}
			else {
				this.router.navigate(['/customers', this.customerId, 'shop', this.siteId, this.distributionId, 'edit-product', addedProduct.product.cartProductId]);
			}
		}
		else {
			this.router.navigate(['/customers', this.customerId, 'shop', this.siteId, this.distributionId, 'edit-printandship-product', addedProduct.product.cartProductId]);
		}
	}

	formatDate(date: Date): string {
		return date.toLocaleDateString('en-US', { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' });
	}

	async applyCoupon(couponCode: string): Promise<void> {
		this.showSpinner = true;
		if (couponCode.length > 0) {
			this.addedProducts.map(x => x.discount = 0);
			this.addedBundleProducts.map(x => x.discount = 0);
		}
		this.couponCode = couponCode;
		this.couponValidationResponse = await this.salesApiService.validateShoppingCartCoupon(this.customerId, new ValidateCoupon({
			siteId: this.siteId!,
			couponCode,
			cartId: 0
		}));
		this.showSpinner = false;
	}

	async clearCoupon() {
		if (this.couponCode.length > 0) {
			await this.applyCoupon('');
		}
	}

	findProduct(productId: number) {
		return this.siteProducts.find(p => p.baseProductId === productId) ?? new WLProduct;
	}

	findUselect(productId: number) {
		const baseProduct = this.findProduct(productId);
		const uSelectId = baseProduct.baseProduct?.uSelectConfigurations![0].uSelectId;
		const uSelectName = this.siteUselects.find(u => u.uSelectId === uSelectId)?.name;
		return uSelectName;
	}

	async populateBundleDisplay() {
        this.siteBundleDisplay = await Promise.all(this.siteBundles.map(async b => await this.bundlesService.getBundle(b.bundleId)));
        this.siteBundleDisplay.sort((a,b) => a.sortOrder - b.sortOrder);
	}

	addProductsToCart() {
		// try {
		// 	this.showSpinner = true;
		// 	const items: AddItemToCartQuery[] = [];
		// 	items.push(new AddItemToCartQuery({
		// 		baseProductId: this.selections?.wlProduct?.baseProductId!,
		// 		distributionId: this.selections?.distributionId!,
		// 		quantity: this.selections?.quantity!,
		// 		design: new Design({
		// 			isProofRequired: this.selections?.design!.isProofRequired ?? false,
		// 			uSelectDesign: this.selections?.design!.uSelectDesign!,
		// 			adText: this.selections?.design!.adText,
		// 			back: this.selections?.design?.back ? new BaseDesign(this.selections?.design?.back) : undefined,
		// 			front: this.selections?.design?.front ? new BaseDesign(this.selections?.design?.front) : undefined,
		// 			designHuddleProjectId: this.selections?.design?.designHuddleProjectId
		// 		}),
		// 		drops: new DropsQuery(this.selections?.drops),
		// 		productOptions: Object.entries(this.selections?.selectedOptionCategories ?? []).map(([optCatId, optionId]) => new ProductOptionIdentifier({ optCatId: parseInt(optCatId, 10), optionId }))
		// 	}));

		// 	if (this.selections?.extraCopies) {
		// 		items.push(new AddItemToCartQuery({
		// 			baseProductId: this.selections?.extraCopies?.productId!,
		// 			distributionId: this.selections?.distributionId!,
		// 			quantity: this.selections?.extraCopies.quantity!,
		// 			productOptions: [],
		// 			shippingAddressId: this.selections?.extraCopies.addressId
		// 		}));
		// 	}
		// 	this.digitalProducts.forEach(p => {
		// 		const bundleItem = this.bundle?.bundleItems?.find(x => x.productConfiguration!.includes(p.productId))!;
		// 		items.push(new AddItemToCartQuery({
		// 			baseProductId: p.productId,
		// 			distributionId: this.selections?.distributionId!,
		// 			design: new Design({
		// 				uSelectDesign: USelectDesignType.ProfessionalDesign,
		// 				isProofRequired: true,
		// 			}),
		// 			quantity: bundleItem.quantity,
		// 			budget: bundleItem.dailySpend,
		// 			drops: new DropsQuery({
		// 				firstDropDate: new Date(p.startDate?.year!, p.startDate?.month! - 1, p.startDate?.day!),
		// 				endDate: new Date(p.endDate?.year!, p.endDate?.month! - 1, p.endDate?.day!),
		// 				kind: DropsKind.MultipleImpressions,
		// 				numberOfDrops: 1,
		// 				weeksBetweenDrops: 1
		// 			})
		// 		}));
		// 	});
		// 	if (this.callTrackingProduct) {
		// 		const bundleItem = this.bundle?.bundleItems?.find(x => x.productConfiguration!.includes(this.callTrackingProduct?.baseProductId!))!;
		// 		const meta: { [key: string]: string; } = {
		// 			"AreaCode": this.areaCode?.toString() ?? '',
		// 			"ForwardingNumber": this.forwardingNumber?.toString() ?? ''
		// 		};
		// 		items.push(new AddItemToCartQuery({
		// 			baseProductId: this.callTrackingProduct.baseProductId!,
		// 			distributionId: this.selections?.distributionId!,
		// 			quantity: bundleItem.quantity,
		// 			budget: bundleItem.dailySpend,
		// 			productOptions: Object.entries(this.callTrackingSelectedOptionCategories).map(([optCatId, optionId]) => new ProductOptionIdentifier({ optCatId: parseInt(optCatId, 10), optionId })),
		// 			meta
		// 		}));
		// 	}
		// 	if (this.qrCodeProduct) {
		// 		const bundleItem = this.bundle?.bundleItems?.find(x => x.productConfiguration!.includes(this.qrCodeProduct?.baseProductId!))!;
		// 		const meta: { [key: string]: string; } = {
		// 			"WebLink": this.qrCodeProduct.baseProductId === 363 ? this.webLink! : '',
		// 			"PhoneNumber": this.qrCodeProduct.baseProductId === 364 ? this.qrCodePhoneNumber! : ''
		// 		};
		// 		items.push(new AddItemToCartQuery({
		// 			baseProductId: this.qrCodeProduct.baseProductId!,
		// 			distributionId: this.selections?.distributionId!,
		// 			quantity: bundleItem.quantity,
		// 			budget: bundleItem.dailySpend,
		// 			productOptions: [],
		// 			meta
		// 		}));

		// 	}
		// 	const query = new BuildMyCartQuery({
		// 		bundleId: this.selections?.bundleId!,
		// 		customerId: 0,
		// 		siteId: this.siteConfigService.siteId,
		// 		items
		// 	});
		// 	await this.cartService.buildCart(query);
		// 	await this.translationService.navigateTranslated(['/shop', 'cart'], { state: { printSelections: this.selections, digitalSelections: this.digitalProducts } });
		// }
		// catch (error) {
		// 	console.log(error);
		// 	this.createCartError = true;
		// }
		// finally {
		// 	this.loading = false;
		// }
	}

	openModal(modal: any, bundleToRemove: Bundle) {
		this.bundleToRemove = bundleToRemove;
		this.modalService.open(modal, {
			backdrop: true,
			keyboard: false,
			size: '',
			modalDialogClass: 'mobile-modal',
			animation: false
		});
	}

	closeModals() {
		this.bundleToRemove = undefined;
		this.modalService.dismissAll();
	}
	async removeBundleFromCart() {
		this.modalService.dismissAll();
		if (!this.bundleToRemove) {
			return;
		}

		const cartBundle = this.shoppingCart?.bundles?.find(b => b.bundleId === this.bundleToRemove?.bundleId);
		if (!cartBundle) {
			return;
		}

		let cartProductIds: string[] = !!cartBundle.cartProductIds ? cartBundle.cartProductIds : [];
		if (cartProductIds.length <= 0) {
			return;
		}

		let success = true;
		this.showSpinner = true;

		// find any extra copies products
		/*const printProduct = this.shoppingCart?.cartData?.find(x => USelect.IsDirectMail(x.uSelectId!))!;
		const ecProduct = this.shoppingCart?.cartData?.find(x => x.uSelectId === USelect.extraCopies && x.paperHeight === printProduct?.paperHeight && x.paperWidth === printProduct.paperWidth);
		if (!!ecProduct) {
			cartProductIds.push(ecProduct.cartProductId!);
		}
		try {
			for (let i = 0; i < cartProductIds.length; i++) {
				await this.shoppingCartService.removeShoppingCart(this.customerId, this.siteId!, cartProductIds[i]);
			}
		}
		catch (ex: any) {
			success = false;
		}
		finally {
			this.showSpinner = false;
		}*/

		try {
			await this.shoppingCartService.removeBundleFromCustomerCart(this.customerId, this.siteId!, cartBundle.bundleId);
		}
		catch (ex: any) {
			success = false;
		}
		finally {
			this.showSpinner = false;
		}
		if (success) {
			this.showSpinner = true;
			await this.loadInitialData();
			this.addedBundleProducts = [];
			this.selectedBundleNew = [];
			this.showSpinner = false;
		}
	}
}
