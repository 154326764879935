import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Printer } from '@taradel/web-api-client';
import { PrintersService } from 'services/printers.service';
import { ToastService } from 'services/toast.service';
import { filter, take } from 'rxjs/operators';
import { zipCodeValidator } from 'app/utils/zip-code-validator';
import { formatCAPostalCode } from 'app/utils/format-ca-postal-code';

@Component({
	selector: 'app-printer',
	templateUrl: './printer.component.html',
	styleUrls: ['./printer.component.scss']
})

export class PrinterComponent implements OnInit {

	loading: boolean = false;
	printerToUpdateForm: UntypedFormGroup;
	printerId: number = 0;
	printers: Printer[] =[];
	printerState = '';
	permitHolderState = '';
	submitted = false;
	countryCode: 'US' | 'Canada' = 'US';

	constructor(private printersService: PrintersService,
		public formBuilder: UntypedFormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private toastService: ToastService) {
		this.route.data.pipe(
			filter((data) => !!data),
			take(1)
		).subscribe(data => this.countryCode = data.countryCode);

		this.printerToUpdateForm = this.formBuilder.group({
			printerId: new FormControl<number | undefined>(undefined, Validators.compose([
				Validators.min(0),
				Validators.required
			])),
			parentPrinterId: new FormControl<string | undefined>(undefined),
			companyName: new FormControl<string | undefined>(undefined, Validators.compose([
				Validators.required
			])),
			primaryContact: new FormControl<string | undefined>(undefined),
			primaryPhone: new FormControl<string | undefined>(undefined),
			primaryFax: new FormControl<string | undefined>(undefined),
			primaryEmail: new FormControl<string | undefined>(undefined),
			secondaryEmail: new FormControl<string | undefined>(undefined),
			tertiaryEmail: new FormControl<string | undefined>(undefined),
			fTPUsername: new FormControl<string | undefined>(undefined),
			fTPPassword: new FormControl<string | undefined>(undefined),
			fTPServer: new FormControl<string | undefined>(undefined),
			fTPDirectory: new FormControl<string | undefined>(undefined),
			postOfficeName: new FormControl<string | undefined>(undefined),
			postOfficeCRID: new FormControl<string | undefined>(undefined),
			permitNumber: new FormControl<string | undefined>(undefined),
			address1: new FormControl<string | undefined>(undefined),
			address2: new FormControl<string | undefined>(undefined),
			city: new FormControl<string | undefined>(undefined),
			state: new FormControl<string | undefined>(undefined),
			zipCode: new FormControl<string | undefined>(undefined, Validators.compose([zipCodeValidator()])),
			enabled: new FormControl<boolean>(false, Validators.compose([
				Validators.required
			])),
			mailerId: new FormControl<string | undefined>(undefined),
			isTaradel: new FormControl<boolean>(false),
			permitHolderCompanyName: new FormControl<string | undefined>(undefined),
			permitHolderAddress1: new FormControl<string | undefined>(undefined),
			permitHolderAddress2: new FormControl<string | undefined>(undefined),
			permitHolderCity: new FormControl<string | undefined>(undefined),
			permitHolderState: new FormControl<string | undefined>(undefined),
			permitHolderZipCode: new FormControl<string | undefined>(undefined, Validators.compose([zipCodeValidator()])),
			permitHolderCapsNumber: new FormControl<string | undefined>(undefined),
			permitHolderCRID: new FormControl<string | undefined>(undefined),
			permitHolderPhoneNumber: new FormControl<string | undefined>(undefined),
			bundleExtraCopies: new FormControl<boolean>(false, Validators.compose([
				Validators.required
			])),
			bundlePostage: new FormControl<boolean>(false, Validators.compose([
				Validators.required
			])),
			excludeNonProfit: new FormControl<boolean>(false, Validators.compose([
				Validators.required
			])),
		});
	}

	ngOnInit() {
		this.route.paramMap.subscribe(async params => {
			try {
				this.loading = true;
				this.printers = await this.printersService.getAllPrinters();
				this.printerId = parseInt(params.get('printerId') ?? '0', 10);
				this.printers = this.printers.filter(p => p.printerId !== this.printerId);
				const printer = await this.printersService.getPrinter(this.printerId);
				this.initializePrinterToUpdateForm(printer);
			}
			catch {
				this.toastService.showError('There was an error getting the printer');
			}
			finally {
				this.loading = false;
			}
		});
	}

	get controls() { return this.printerToUpdateForm.controls; }

	initializePrinterToUpdateForm(printer: Printer) {
		this.controls.printerId.setValue(printer.printerId);
		this.controls.parentPrinterId.setValue(printer.parentPrinterId);
		this.controls.companyName.setValue(printer.companyName);
		this.controls.primaryContact.setValue(printer.primaryContact);
		this.controls.primaryPhone.setValue(printer.primaryPhone);
		this.controls.primaryFax.setValue(printer.primaryFax);
		this.controls.primaryEmail.setValue(printer.primaryEmail);
		this.controls.secondaryEmail.setValue(printer.secondaryEmail);
		this.controls.tertiaryEmail.setValue(printer.tertiaryEmail);
		this.controls.fTPUsername.setValue(printer.ftpUsername);
		this.controls.fTPPassword.setValue(printer.ftpPassword);
		this.controls.fTPServer.setValue(printer.ftpServer);
		this.controls.fTPDirectory.setValue(printer.ftpDirectory);
		this.controls.postOfficeName.setValue(printer.postOfficeName);
		this.controls.postOfficeCRID.setValue(printer.postOfficeCRID);
		this.controls.permitNumber.setValue(printer.permitNumber);
		this.controls.address1.setValue(printer.address1);
		this.controls.address2.setValue(printer.address2);
		this.controls.city.setValue(printer.city);
		this.controls.state.setValue(printer.state);
		this.printerState = printer.state ?? '';
		this.controls.zipCode.setValue(printer.zipCode);
		this.controls.enabled.setValue(printer.enabled);
		this.controls.mailerId.setValue(printer.mailerId);
		this.controls.isTaradel.setValue(printer.isTaradel);
		this.controls.permitHolderCompanyName.setValue(printer.permitHolderCompanyName);
		this.controls.permitHolderAddress1.setValue(printer.permitHolderAddress1);
		this.controls.permitHolderAddress2.setValue(printer.permitHolderAddress2);
		this.controls.permitHolderCity.setValue(printer.permitHolderCity);
		this.controls.permitHolderState.setValue(printer.permitHolderState);
		this.permitHolderState = printer.permitHolderState ?? '';
		this.controls.permitHolderZipCode.setValue(printer.permitHolderZipCode);
		this.controls.permitHolderCapsNumber.setValue(printer.permitHolderCapsNumber);
		this.controls.permitHolderCRID.setValue(printer.permitHolderCRID);
		this.controls.permitHolderPhoneNumber.setValue(printer.permitHolderPhoneNumber);
		this.controls.bundleExtraCopies.setValue(printer.bundleExtraCopies);
		this.controls.bundlePostage.setValue(printer.bundlePostage);
		this.controls.excludeNonProfit.setValue(printer.excludeNonProfit);
	}

	async updatePrinter(): Promise<number> {
		const updatedPrinter = {
			printerId: this.controls.printerId.value,
			parentPrinterId: this.controls.parentPrinterId.value,
			companyName: this.controls.companyName.value,
			primaryContact: this.controls.primaryContact.value,
			primaryPhone: this.controls.primaryPhone.value,
			primaryFax: this.controls.primaryFax.value,
			primaryEmail: this.controls.primaryEmail.value,
			secondaryEmail: this.controls.secondaryEmail.value,
			tertiaryEmail: this.controls.tertiaryEmail.value,
			ftpUsername: this.controls.fTPUsername.value,
			ftpPassword: this.controls.fTPPassword.value,
			ftpServer: this.controls.fTPServer.value,
			ftpDirectory: this.controls.fTPDirectory.value,
			postOfficeName: this.controls.postOfficeName.value,
			postOfficeCRID: this.controls.postOfficeCRID.value,
			permitNumber: this.controls.permitNumber.value,
			address1: this.controls.address1.value,
			address2: this.controls.address2.value,
			city: this.controls.city.value,
			state: this.controls.state.value,
			zipCode: this.countryCode === 'US' ? this.controls.zipCode.value : (!!this.controls.zipCode.value && this.controls.zipCode.value !== '' ? formatCAPostalCode(this.controls.zipCode.value) : ''),
			enabled: this.controls.enabled.value,
			mailerId: this.controls.mailerId.value,
			isTaradel: this.controls.isTaradel.value,
			permitHolderCompanyName: this.controls.permitHolderCompanyName.value,
			permitHolderAddress1: this.controls.permitHolderAddress1.value,
			permitHolderAddress2: this.controls.permitHolderAddress2.value,
			permitHolderCity: this.controls.permitHolderCity.value,
			permitHolderState: this.controls.permitHolderState.value,
			permitHolderZipCode: this.countryCode === 'US' ? this.controls.permitHolderZipCode.value : (!!this.controls.permitHolderZipCode.value && this.controls.permitHolderZipCode.value !== '' ? formatCAPostalCode(this.controls.permitHolderZipCode.value) : ''),
			permitHolderCapsNumber: this.controls.permitHolderCapsNumber.value,
			permitHolderCRID: this.controls.permitHolderCRID.value,
			permitHolderPhoneNumber: this.controls.permitHolderPhoneNumber.value,
			bundleExtraCopies: this.controls.bundleExtraCopies.value,
			bundlePostage: this.controls.bundlePostage.value,
			excludeNonProfit: this.controls.excludeNonProfit.value
		} as Printer;

		return await this.printersService.updatePrinter(updatedPrinter);
	}

	printerStateSelected(value: string) {
		this.printerState = value;
		this.controls.state.setValue(value);
	}

	permitHolderprinterStateSelected(value: string) {
		this.permitHolderState = value;
		this.controls.permitHolderState.setValue(value);
	}

	async update() {
		this.submitted = true;
		if (this.printerToUpdateForm.invalid) {
			this.toastService.showError('Form is invalid');
			return;
		}
		this.loading = true;
		let result = 0;
		try {
			result = await this.updatePrinter();
		}
		catch (error) {
			console.log(error);
			this.toastService.showError('Printer could not be updated');
		}
		finally {
			this.loading = false;
		}
		if (result > 0) {
			const printer = await this.printersService.getPrinter(this.printerId);
			this.initializePrinterToUpdateForm(printer);
			this.toastService.showSuccess('Printer was updated');
		}
	}

}
